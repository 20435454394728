@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap");

/* //CSS variables */
:root {
  --pokemon-red: #ce2222;
  --light-gray: #f5f5f5;
}

/* Basic Reset / Fonts */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: Arial;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
